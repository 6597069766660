import React from "react"
import { navigate } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Button from "../components/Button"
import { fonts } from "../styles"

const NotFoundPage = () => (
  <Layout title="404">
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <h1
        css={css`
          font-family: ${fonts.sans};
        `}
      >
        无法找到该页
      </h1>
      <p>最可能的原因</p>
      <ul>
        <li>在地址中可能存在键入错误</li>
        <li>在您点击某个链接时，它可能已经过期。</li>
      </ul>
      <br />
      <Button onClick={() => navigate("/")}>返回主页</Button>
    </div>
  </Layout>
)

export default NotFoundPage
